<template>
  <div v-if="hasResource('subscription_code')">
    <div class="aops-code">
      <div class="aop-searchForm search-form">
        <el-button type="primary" class="black-btn" @click="add">新增</el-button
      >
        <div class="aop-fix">
          <el-form ref="listFilter" :inline="true" :model="filter" :rules="rules" >
             <el-form-item label="订阅状态" prop="status">
              <el-select v-model="filter.status">
                <el-option v-for="item in codeStatus" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="邮件状态" prop="email_status">
                <el-select v-model="filter.email_status">
                <el-option
                    v-for="item in emailOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订阅版本" prop="type">
              <el-select v-model="filter.type">
                <el-option v-for="code in codeType" :key="code.value" :label="code.label" :value="code.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label=" 订阅码" prop="code">
              <el-input
                v-model="filter.code"
                placeholder="输入订阅码检索"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户名称"  prop="username">
              <el-input
                v-model="filter.username"
                placeholder="请输入用户名称检索"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
             <el-form-item label="用户邮箱"  prop="email">
            <el-input
              v-model="filter.email"
              placeholder="输入邮箱检索"
              clearable
              @keyup.enter.native="addByEnterKey"
            />
             </el-form-item>
            <el-form-item label="订阅时间"  prop="date">
              <el-date-picker
                v-model="filter.date"
                style="width: 168px;"
                type="daterange"
                range-separator="-"
                value-format="timestamp"
                start-placeholder="请选择"
                end-placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="赠送时长" prop="duration" style="position: relative;">
              <el-input
                v-model="filter.duration"
                placeholder="0"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
              <span class="aop-day">天</span>
            </el-form-item>
             <el-form-item label="包含积分" prop="balance">
              <el-input
                v-model="filter.balance"
                placeholder="0"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="aop-textBtn">
            <span class="aop-clickButton" @click="fetchList">查询</span>
            <span class="aop-clearButton aop-clickButton" @click="reset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        v-loading="isTableLoading"
        :data="data"
        style="width: 100%;"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
      >
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop === 'type'">
                  {{`${row[col.prop]}` === '1' ? '个人版': '专业版'}}
                </div>
               <div v-else-if="col.prop === 'status'">
                  {{getStatus(row[col.prop])}}
                </div>
               <div v-else-if="col.prop === 'email_status'">
                  {{emailStatus[row[col.prop]] || '--'}}
                </div>
                <div v-else-if="col.prop === 'activate_at' || col.prop === 'failure_at'">
                   {{row[col.prop]? $moment(new Date(row[col.prop] * 1000)).format('YYYY-MM-DD') : '--'}}
                </div>
                <div v-else-if="col.prop === 'operation'" class="pa_icon-btn icon-btn">
                    <div v-if="`${row.status}` === '1'" class="icon-btn">
                        <el-button
                            v-clipboard:copy="row.code"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            size="mini"
                            type="text"
                        >
                            <i class="iconfont">&#xe69a;</i> 复制订阅码
                        </el-button>
                        <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            @click="handleSendEmail(row)"
                        >
                            <i class="iconfont">&#xe661;</i> 发送邮件
                        </el-button>
                    </div>
                </div>
                <div v-else>{{row[col.prop] || '--'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div v-if="total != 0"  class="aop-pagination">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <sendEmailDialog
        ref="sendEmailPop"
        type="subscription"
        @updateList="fetchList"
    />
  </div>
</template>
<script>
import { toDate, formatDate } from 'ecoplants-lib';
import sendEmailDialog from '@/pages/_common/send_email_dialog/send_email_dialog';
import api from '@/api/subscription.js';
import { tableColumns, codeStatus, codeType, filter, rules, emailStatus, emailOption } from './data.js';

export default {
    components: { sendEmailDialog },
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            tableColumns,
            codeStatus,
            codeType,
            rules,
            isTableLoading: false,
            total: 0,
            pageSize: 10,
            currentPage: 1,
            data: [],
            filter,
            emailStatus, // 邮件状态
            emailOption
        };
    },
    /**
    * 创建时
    */
    created() {
        this.fetchList();
    },
    methods: {
        /**
        * 查询
         * @param {*} e 值
        */
        addByEnterKey(e) {
            if (e.keyCode == 13) { this.fetchList(); }
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.fetchList();
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.fetchList();
        },
        /**
        * 重置
        */
        reset() {
            this.$refs.listFilter.resetFields();
            this.currentPage = 1;
            this.filter = { filter };
        },
        /**
        * 列表数据
           @returns{*}
        */
        fetchList() {
            this.isTableLoading = true;
            let startTime;
            let endTime;
            if (this.filter.date && this.filter.date.length > 0) {
                startTime = this.filter.date[0] / 1000;
                endTime = toDate(formatDate(this.filter.date[1], 'yyyy-MM-dd'), true).getTime() / 1000;
                endTime = Math.floor(endTime);
            }
            const data = {
                limit: this.pageSize,
                page: this.currentPage,
                filter: { ...this.filter, end_time: endTime, start_time: startTime }
            };
            try {
                api.fetchList(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.data = res.data.data.items;
                        this.total = res.data.data.total;
                        this.currentPage = res.data.data.page;
                    }
                    this.isTableLoading = false;
                });
            } catch (e) {
                return e;
            }
        },
        /** * 成功  */
        onCopy() { this.$$success('该订阅码复制成功'); },
        /** * 失败 */
        onError() { this.$$error('该订阅码复制失败'); },
        /** * 创建 */
        add() { this.$router.push('/subscribe-add'); },
        /**
        * 查询
         * @param {*} val 值
         @return {*}
        */
        getStatus(val) {
            const child = this.codeStatus.find((item) => item.value === val);
            return (child && child.label) || '--';
        },
        /**
         * 点击发送邮件，打开弹窗
         * @param {object} val 该条订阅码数据
        */
        handleSendEmail(val) {
            // 已经发送过，点击发送邮件，直接发送邮件
            if (val.customer_id) { this.sendEmail(val); } else {
                this.$nextTick(() => { this.$refs.sendEmailPop.show(val); });
            }
        },
        /**
         * 发送邮件
         * @param {*} row 订阅码
        */
        async sendEmail(row) {
            const resp = await api.sendEmail({ id: row.id, customer_id: row.customer_id });
            if (resp.data.code === 10200) { this.$$success('订阅码邮件已重新发送'); } else {
                this.$$error(resp.data.message);
                this.init();
            }
        }
    }
};
</script>

<style lang="less">
    @import "./list.less";
</style>
