import input from '@/uitls/inputCheck';

export const tableColumns = [
    { prop: 'id', label: '编号', width: 160, align: 'left' },
    { prop: 'code', label: '订阅码', width: 240, align: 'left' },
    { prop: 'status', label: '状态', width: 140, align: 'left' },
    { prop: 'email_status', label: '邮件状态', width: 140, align: 'left' },
    { prop: 'type', label: '版本', width: 140, align: 'left' }, // 1:个人版本， 2： 专业版本
    { prop: 'username', label: '用户名称', width: 160, align: 'left' },
    { prop: 'email', label: '用户邮箱', width: 190, align: 'left' },
    { prop: 'activate_at', label: '订阅码生效日期', width: 190, align: 'left' },
    { prop: 'failure_at', label: '订阅码失效日期', width: 190, align: 'left' },
    { prop: 'duration', label: '赠送订阅时长', width: 140, align: 'left' },
    { prop: 'balance', label: '包含积分', align: 'left' }, // 1未使用 2：已使用
    { prop: 'operation', label: '操作', width: 284, align: 'left', fixed: 'right' }
];
export const emailStatus = {
    1: '未发送',
    2: '已发送',
    3: '发送中'
};
export const emailOption = [
    {
        value: 1,
        label: '未发送'
    },
    {
        value: 2,
        label: '已发送'
    },
    {
        value: 3,
        label: '发送中'
    }
];
export const filter = {
    code: '',
    type: '',
    status: '',
    username: '',
    email: '',
    start_time: '',
    end_time: '',
    duration: '',
    balance: '',
    date: '',
    email_status: '' // 邮件发送状态
};

export const addForm = {
    type: '',
    failure_at: '',
    duration: '',
    balance: '',
    total: ''
};

export const codeType = [
    {
        value: '',
        label: '全部'
    },
    {
        value: 1,
        label: '个人版'
    },
    {
        value: 2,
        label: '专业版'
    }
];

export const codeStatus = [
    {
        value: '',
        label: '全部'
    },
    {
        value: 1,
        label: '未使用'
    },
    {
        value: 2,
        label: '已使用'
    },
    {
        value: 3,
        label: '已失效'
    }
];

export const selectType = [
    {
        value: 1,
        label: '个人版'
    },
    {
        value: 2,
        label: '专业版'
    }
];

/**
 *checkBalanceNumber
 * @param {*} rule rule
 * @param {*} value value
 * @param {*} callback callback
 */
const checkNumber = (rule, value, callback) => {
    setTimeout(() => {
        const res = input.checkIsNumber(value);
        if (res !== -1) {
            callback(input.checkFormErrorMsg(res));
        }
        if (value <= 0) {
            callback('请输入大于零的数');
        }
        callback();
    }, 200);
};

/**
 *checkBalanceNumber
 * @param {*} rule rule
 * @param {*} value value
 * @param {*} callback callback
 */
const checkIsNumber = (rule, value, callback) => {
    setTimeout(() => {
        const res = input.checkIsNumber(value);
        if (res !== -1) {
            callback(input.checkFormErrorMsg(res));
        }
        if (value <= 0) {
            callback('请输入大于零的数');
        }
        if (value > 200) {
            callback('请输入小于200的数');
        }
        callback();
    }, 200);
};
/**
 *checkBalance
 * @param {*} rule rule
 * @param {*} value value
 * @param {*} callback callback
 */
const checkBalance = (rule, value, callback) => {
    setTimeout(() => {
        const reg = /^[0-9]*$/;
        if (!reg.test(value)) {
            callback(new Error('请输入整数'));
        }
        if (value < 0) {
            callback('请输入大于零的数');
        }
        if (value > 100) {
            callback('请输入小于100的数');
        }
        callback();
    }, 200);
};

/**
 *checkDuration
 * @param {*} rule rule
 * @param {*} value value
 * @param {*} callback callback
 */
const checkDuration = (rule, value, callback) => {
    setTimeout(() => {
        const res = input.checkIsNumber(value);
        if (res !== -1) {
            callback(input.checkFormErrorMsg(res));
        }
        if (value <= 0) {
            callback('请输入大于零的数');
        }
        if (value > 75) {
            callback('请输入小于75的数');
        }
        callback();
    }, 200);
};

export const rules = {
    code: [
        { min: 1, max: 22, message: '输入内容不可超过22个字符', trigger: 'blur' }
    ],
    balance: [
        { validator: checkBalance, trigger: 'blur' }
    ],
    duration: [
        { validator: checkNumber, trigger: 'blur' }
    ]
};

export const addRules = {
    type: [{
        required: true,
        message: '请选择订阅码版本',
        trigger: 'blur'
    }
    ],
    failure_at: [{
        required: true,
        message: '请选择日期',
        trigger: 'blur'
    }
    ],
    duration: [
        {
            required: true,
            message: '请输入时长',
            trigger: 'blur'
        }, {
            validator: checkDuration,
            trigger: 'blur'
        }
    ],
    balance: [
        {
            required: false,
            validator: checkBalance,
            trigger: 'blur'
        }
    ],
    total: [{
        required: true,
        message: '请填写数量',
        trigger: 'blur'

    }, {
        validator: checkIsNumber,
        trigger: 'blur'
    }
    ]
};
